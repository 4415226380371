// @flow
import React from 'react';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  GlossaryLink as G,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';
import { graphql } from 'gatsby';
import routes from '../../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {
    P8060119: ImageType,
    P8060141: ImageType,
    P8060146: ImageType,
    P8060150: ImageType,
    P8060191: ImageType,
    P8060195: ImageType,
    P8060247: ImageType,
    P8060264: ImageType,
    P8060330: ImageType,
  },
};

class Blog_2021_08_06_South_Fork_Snoqualmie extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CIII" title="South Fork Snoqualmie" />
        <p>
          I took a trip up to the Pacific Northwest to take a <G>swift water</G>{' '}
          canyoneering course with{' '}
          <Link to="https://getinthewild.com/">Get In The Wild</Link>.
        </p>
        <h2>Day 1</h2>
        <p>
          Day 1 was mostly a ground school. The other students and myself were a
          little more experienced than a normal introduction to canyoneering
          course so we glossed over a lot of it.
        </p>
        <p>
          A couple of the highlights were that we practiced ascending and
          descending with friction hitches and mechanical ascenders. I hadn't
          used friction hitches in a while so it was good practice to move up
          and down with those. I had also never tried descending with a toothed
          mechanical ascender. It was a terrible experience and I absolutely
          hated it.
        </p>
        <p>
          We finished things really early that day and so we spent the last few
          hours going over jumps and slides. This was one of the highlights of
          the trip and I'll go over it more on Day 2.
        </p>
        <h2>Day 2</h2>
        <p>
          Ground school again in the morning. We went over swift water hazards,
          rigging, rappelling, and locking off while on rappel. The swift water
          hazards were all new to me and it was great to finally be able to
          learn about this. I'll update the website soon with more accurate
          information regarding water hazards and swift water features.
        </p>
        <p>
          After that we went back into{' '}
          <Link to="http://ropewiki.com/Snoqualmie_River_(South_Fork)">
            South Fork Snoqualmie
          </Link>
          !
        </p>
        <Image
          image={data.P8060119}
          caption={
            <>
              Our sky on the approach. I find it funny that in the{' '}
              <G>Colorado Plateau</G> if you see even a little cloud you worry
              and in the <G>PNW</G> if you see even a little blue sky you cheer.
            </>
          }
        />
        <Image
          image={data.P8060141}
          caption="Scouting the drop in point to decide if the water is too high to descend. We had been in here the day before and it looked slightly higher but not enough to be a worry."
        />
        <Image
          image={data.P8060146}
          caption={
            <>
              Practicing our first <G>meat anchor</G>
            </>
          }
        />
        <Image
          image={data.P8060150}
          caption={
            <>
              <G id="zip">Zipping</G> packs down. Standard zipline procedure is
              to clip a <G>carabiner</G> on the top of the pack and leave it
              there for the duration of the canyon. I have historically hated
              this because the carabiner always hits me in the head when I put
              the pack on or do any awkward down climbing. The lead instructor
              suggested clipping the carabiner into an additional loop in case
              the main loop fails but I also noticed that when I do this it
              doesn't hit me in the head anymore. I will do this in the future
              in canyons when we are doing lots of zipping.
            </>
          }
        />
        <Image
          image={data.P8060191}
          caption={
            <>
              Rigging for our one and only rappel of the day. I might have a
              better picture of the <G>rigging</G> on some of the other days. It
              was really weird and I have never seen it before. It was a{' '}
              <G>contingency redirect</G> using a <G>figure 8 rappel device</G>{' '}
              with an ultra-general lock off. The lead instructor called it an
              ADS (Any Device System). Let's see if I can describe it.
              <ul>
                <li>
                  <G>Rappel line</G> is threaded through the <G>rapide</G> like
                  always.
                </li>
                <li>
                  Carabiner is clipped into the same rapide and then a figure 8
                  device below that. The rope must be above the carabiner so it
                  doesn't get pinched between the carabiner and the rapide.
                </li>
                <li>
                  For the lock off the rope wraps back up and around the{' '}
                  <G>rappel side</G> and the big hole of the 8. I've heard type
                  of lock off called the pop-and-lock. After this you wrap the
                  rope around the skinny part of the 8, kind of like the extra
                  half-wrap on a <G>compact secure</G>. Then you take a{' '}
                  <G>bight</G> and put it through the big hole of the 8. Finally
                  you use that bight to tie a <G id="mule-hitch">mule</G>/
                  <G id="overhand-on-a-bight">overhand</G>.
                </li>
              </ul>
            </>
          }
        />
        <Image
          image={data.P8060195}
          caption={
            <>
              This rappel was intentionally rigged a few feet above the water
              and we were encouraged to let go early and fall in. Oh this is the
              first person down posing while being lowered.
            </>
          }
        />
        <Image image={data.P8060247} caption="View from below" />
        <Image
          image={data.P8060264}
          caption="I believe this is a variation of the defensive swimming position called the sleeping swimming position"
        />
        <Image
          image={data.P8060330}
          caption="One of the many slides in the canyon"
        />
        <p>
          Those were the first two days. Next day is{' '}
          <Link to={routes.blog_2021_08_07_hall_creek}>Hall Creek</Link>.
        </p>
        <AllImagesLink id="2021-08-06-south-fork-snoqualmie" />
      </Layout>
    );
  }
}

export default withFrontMatter('2021-08-06-south-fork-snoqualmie')(
  Blog_2021_08_06_South_Fork_Snoqualmie
);

export const query = graphql`
  query {
    P8060119: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060119.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060141: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060141.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060146: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060146.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060150: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060150.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060191: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060191.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060195: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060195.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060247: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060247.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060264: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060264.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8060330: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060330.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
